import {NgModule} from '@angular/core';
import {Routes, RouterModule, ExtraOptions} from '@angular/router';
import {NgxPageScrollCoreModule} from 'ngx-page-scroll-core';


const routes: Routes = [

  { path: '', redirectTo: '/home', pathMatch: 'full' },

  { path: 'home', loadChildren: './pages/home/home.module#HomeModule' },

  { path: 'account', loadChildren: './pages/account/account.module#AccountModule'},
  /* END MY VIEWS */


];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
