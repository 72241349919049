import {Component, OnInit} from '@angular/core';
import {SubscribersService} from '../../services/subscribers.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  private item: any = {};

  constructor(private subscriberService: SubscribersService) {
  }

  ngOnInit() {
  }


  subscribeNewsLetter() {
    this.item.subscribe = true;
    this.subscriberService.create(this.item);
  }

}
