import {Injectable} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from '@angular/fire/firestore';
import {Customer} from '../model/customer';
import {AngularFireFunctions} from '@angular/fire/functions';
import {AngularFireAuth} from '@angular/fire/auth';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable()
export class AccountService {

  customer: any;
  private customerCollection: AngularFirestoreCollection<Customer>;

  constructor(
    private afs: AngularFirestore,
    private fns: AngularFireFunctions,
    private afAuth: AngularFireAuth,
  ) {
    this.customerCollection = afs.collection<Customer>('customer');
  }


  // CRUD METHODS


  /**
   * UserService.get
   *   @description CRUD ACTION get
   *   @param ObjectId id Id resource
   *
   */
  get(phoneNumber: string): Observable<Customer[]> {
    return this.afs.collection('customer', ref => ref.where('phonenumber', '==', phoneNumber)).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Customer;
        const id = a.payload.doc.id;
        return {id, ...data};
      })));


  }


  // Custom APIs


  /**
   * UserService.changePassword
   *   @description Change password of user from admin
   *   @returns object
   *
   */
  changePassword(...params: any[]): Observable<any> {
    const callable = this.fns.httpsCallable('change-password');
    return callable(params);
  }
}
