// DEPENDENCIES
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection, DocumentReference} from '@angular/fire/firestore';
import {AngularFireFunctions} from '@angular/fire/functions';
import {Subscribers} from '../model/subscribers';

// CONFIG

@Injectable()
export class SubscribersService {
  private adsCollection: AngularFirestoreCollection<Subscribers>;


  constructor(
    private afs: AngularFirestore,
    private fns: AngularFireFunctions
  ) {
    this.adsCollection = afs.collection<Subscribers>('subscribers');
  }

  // CRUD METHODS

  /**
   * adsService.create
   *   @description CRUD ACTION create
   *
   */
  create(item: Subscribers): Promise<DocumentReference> {
    return this.adsCollection.add(item);
  }

  /**
   * adsService.delete
   *   @description CRUD ACTION delete
   *   @param ObjectId id Id
   *
   */
  remove(id: string) {
    const itemDoc: AngularFirestoreDocument<any> = this.adsCollection.doc(id);
    itemDoc.delete();
  }

  /**
   * adsService.get
   *   @description CRUD ACTION get
   *   @param ObjectId id Id resource
   *
   */
  get(id: string): AngularFirestoreDocument<Subscribers> {
    return this.afs.doc<Subscribers>('subscribers/' + id);
  }

  /**
   * adsService.list
   *   @description CRUD ACTION list
   *
   */
  list(): Observable<Subscribers[]> {

    return this.afs.collection('subscribers').snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Subscribers;
        const id = a.payload.doc.id;
        return {id, ...data};
      }))
    );


  }

  /**
   * adsService.update
   *   @description CRUD ACTION update
   *   @param ObjectId id Id
   *
   */

  update(itemDoc: AngularFirestoreDocument<Subscribers>, item: Subscribers): Promise<void> {
    return itemDoc.update(item);
  }


  // Custom APIs

}
